let spSuggestListClick = false;

function suggest() {
  let timer = false;
  $('.triggerKeyword').on('input', function () {
    if (timer) {
      clearTimeout(timer);
    }

    $('.questionnaire_banner').removeClass('hidden');
    const value = $(this).val();
    timer = setTimeout(() => {
      // 入力時の処理

      if (!value) {
        $('.triggerSidebarNav').removeClass('hidden');
        $('.gestSpace').removeClass('hidden');
        $('.triggerSuggest').css('display', 'none');
        $('.triggerSuggest').empty();
        return; // 空文字の時は通信しない
      }
      $.ajax({
        url: '/api/search/suggest/',
        type: 'GET',
        data: {
          keyword: value,
        },
      }).done(data => {
        let html = '';
        html += '<ul>';
        if (data && data.results.length > 0) {
          $('.triggerSidebarNav').addClass('hidden');
          $('.gestSpace').addClass('hidden');
          $('.triggerSuggest').css('display', 'block');
          $('.menuAfter').removeClass('menuAfter');
          $('.menuNormal').removeClass('menuNormal');
          for (let i = 0; i < data.results.length; i += 1) {
            html += '<li><a class="suggest-link" href="/project/';
            html += data.results[i].code;
            html += `?from=suggest&keyword=${value}&disp_order=${i + 1}`;
            html += '"><span>';
            html += data.results[i].title;
            html += '</span></a></li>';
          }
          html +=
            '</ul><button type="submit" class="suggest-button">検索結果をもっと見る</button>';
          $('.triggerSuggest').html(html);
        }
      });
    }, 250);
  });

  $('.triggerKeyword').on('blur', e => {
    e.preventDefault();
    if (spSuggestListClick) {
      spSuggestListClick = false;
      return;
    }
    if ($('div.triggerSuggest').css('display') === 'none') {
      return;
    }
    $('.triggerSidebarNav').removeClass('hidden');
    $('.gestSpace').removeClass('hidden');
    $('.triggerSuggest').css('display', 'none');
    $('.triggerSuggest').empty();
  });

  $('div.triggerSuggest').on('mousedown', 'li', () => {
    spSuggestListClick = true;
  });
  $('div.triggerSuggest').on('mousedown', 'button', () => {
    spSuggestListClick = true;
  });
}

$(() => {
  suggest();
});
